import {React, useState, useEffect }from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Header from '../../components/header/Header'
import './allorders.css'
import { Button,Modal,ModalHeader } from 'reactstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Axios  from 'axios'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router-dom';


export default function Allorders() {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const status = queryParams.get('status');
    const[modal, setModal] = useState(false)
    const[start, setStart] = useState('')
    const[end, setEnd] = useState('')
    const[stores, setStores] = useState([])
    const[allorders, setAllorders] = useState([])
    const[riders, setRiders] = useState([])
    const[store, setStore] = useState('')
    const[rider, setRider] = useState('')
    const [data, setData] = useState([]);
    const [efficiency, setEfficiency] = useState('')
    const [selectedOrder, setSelectedorder] = useState('')
    const [status1, setStatus1] = useState('')
    const [store1, setStore1] = useState('')
    const [customer,setCustomer] = useState('')
    const [phone, setPhone] = useState('')
    const [value, setValue] = useState('')
    const [location, setLocation] = useState('')
    const [distance, setDistance] = useState('')
    const [createdAt, setCreatedAt] = useState('')
    const [rider1, setRider1] = useState('')
    const [loading, setLoading] = useState(false)
    const [orderItems, setOrderitems] = useState([])
    

    const handlePrint = () => {
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write('<html><head><title>Print Table</title></head><body >');
      printWindow.document.write(document.querySelector('table').outerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    };
  
    // Function to download table data as CSV
    const handleDownloadCSV = () => {
      const rows = [];
      const headers = Array.from(document.querySelectorAll('table thead th')).map(th => th.innerText);
      rows.push(headers.join(','));
      
      const dataRows = Array.from(document.querySelectorAll('table tbody tr')).map(tr => 
        Array.from(tr.querySelectorAll('td')).map(td => td.innerText).join(',')
      );
      rows.push(...dataRows);
      
      const csvContent = rows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'yunshuorders.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };




  const toggle=async(e)=>{
    const id = e.target.value;
    setSelectedorder(id)
    setModal(!modal)

    const res = await Axios.get(`https://adminapi.albatroxlogistics.com/api/getOrder?id=${id}`)
    setStatus1(res.data.status)
    setStore1(res.data.store)
    setCustomer(res.data.customerName)
    setPhone(res.data.customerPhone)
    setValue(res.data.orderValue)
    setLocation(res.data.location)
    setDistance(res.data.distance)
    setCreatedAt(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(res.data.postTime))
    setRider1(res.data.ridername)
    setLoading(true)

    const res1 = await Axios.get(`https://adminapi.albatroxlogistics.com/api/getOrderitems?id=${id}`);
    setOrderitems(res1.data)


    

  }
  useEffect(() => {
    // Fetch data from the backend
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders2?status=${status}`)
      .then(response => {
        setData(response.data);
       
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);


    const handleDateChange = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setStart(millisecondsSinceEpoch);
    
      };
      const handleDateChange2 = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setEnd(millisecondsSinceEpoch);
      };

      useEffect(()=>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAllstores').then((res)=>{
            setStores(res.data)
        })
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAllriders').then((res)=>{
            setRiders(res.data)
        })
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAllorders').then((res)=>{
            setAllorders(res.data)
        })

    },[])

    useEffect(() => {
  if (start && end && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders3?start=${start}&end=${end}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
}, [start, end,status]);

useEffect(() => {
  if (start && end && store && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders4?start=${start}&end=${end}&store=${store}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
  if (store && start == '' && end=='' && efficiency == '' && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders6?store=${store}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  if (rider && store == '' && start == '' && end=='' && efficiency == '' && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders7?rider=${rider}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
  if (rider && store && start == '' && end=='' && efficiency == '' && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders8?rider=${rider}&store=${store}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
  if (rider && store && start && end && efficiency == '' && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders5?rider=${rider}&store=${store}&start=${start}&end=${end}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
  if (rider =='' && store =='' && start=='' && end=='' && efficiency && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders9?efficiency=${efficiency}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
  if (rider && store =='' && start=='' && end=='' && efficiency && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders10?efficiency=${efficiency}&rider=${rider}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
  if (rider == '' && store && start=='' && end=='' && efficiency && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders11?efficiency=${efficiency}&store=${store}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
  if (rider && store && start=='' && end=='' && efficiency && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders12?efficiency=${efficiency}&store=${store}&rider=${rider}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
  if (rider && store && start && end && efficiency && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders13?efficiency=${efficiency}&store=${store}&rider=${rider}&start=${start}&end=${end}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
  if (rider =='' && store =='' && start && end && efficiency && status) {
    Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllorders14?efficiency=${efficiency}&start=${start}&end=${end}&status=${status}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }
}, [start,end,store,rider,efficiency,status]);



  return (
    <div className='homeWrapper'>
        <Header />
    <div className='homeContainer'>
        <div className="sidebar">
            <Sidebar />
        </div>
        <div className="maincontent">
       

         <div className="filterTabs">
            <div className="filterBytime">
            
            <FloatingLabel className='formInput'  controlId="floatingInputGrid" label='Start perid' onChange={handleDateChange}>
                <Form.Control  className='inputSearch'  type="datetime-local" placeholder='Start period' />
                </FloatingLabel>
                <FloatingLabel className='formInput'  controlId="floatingInputGrid" label='End perid' onChange={handleDateChange2}>
                        <Form.Control  className='inputSearch'  type="datetime-local" placeholder='End period' />
                </FloatingLabel>

            </div>
            <div className="filterBystore">
            <FloatingLabel controlId="floatingInputGrid" label="Select Store" className="formInput"  >
            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setStore(e.target.value)}}  >
                    <option selected disabled>Select store</option>
                    <option value="All">All</option>
                    {stores.map((val)=>{
                        return(
                            <option value={val.storename}>{val.storename}</option>
                        )
                    })}                  
                </Form.Select>
                        
            </FloatingLabel>

            </div>
            <div className="filterByriders">
            <FloatingLabel controlId="floatingInputGrid" label="Select rider" className="formInput" >
            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setRider(e.target.value)}}  >
                <option selected disabled>Select rider</option>    
                <option value="All">All</option>  
                {riders.map((val)=>{
                    return(
                        <option value={val.rideremail}>{val.ridername}</option>
                    )
                })}                
            </Form.Select>
                    
            </FloatingLabel>

            </div>
            <div className="filterBydispatcher">
            <FloatingLabel controlId="floatingInputGrid" label="Select dispatcher" className="formInput" >
                <Form.Select className="inputSearch"  aria-label="Floating label select example"  >
                    <option selected disabled>Select dispatcher</option>    
                    {/* <option value="All">All</option>  
                    {riders.map((val)=>{
                        return(
                            <option value={val.rideremail}>{val.ridername}</option>
                        )
                    })}                 */}
                </Form.Select>
                    
            </FloatingLabel>

            </div>
            <div className="filterByefficiency">
            <FloatingLabel controlId="floatingInputGrid" label="Select efficiency" className="formInput" >
            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setEfficiency(e.target.value)}}   >
                <option selected disabled>Select option</option>   
                <option value="2700000">in 45min</option> 
                <option value="3600000">in 1hour</option>
                <option value="5400000">in 1hour 30min</option>
                <option value="5400000">After 1hour 30min</option>
                {/* <option value="All">All</option>  
                {riders.map((val)=>{
                    return(
                        <option value={val.rideremail}>{val.ridername}</option>
                    )
                })}                 */}
            </Form.Select>
                    
            </FloatingLabel>

            </div>
         </div>

         <div className="ordersTable">
         <h5 className='tabTitle'>All orders({data.length})</h5>
         <div>
         <div className='actionButtons'>
            <Button color='info' onClick={handlePrint}>Print</Button>
            <Button color='success' onClick={handleDownloadCSV}>Download CSV</Button>
            {/* <button onClick={handlePrint} >Print</button>
            <button onClick={handleDownloadCSV}>Download CSV</button> */}
        </div>
         <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight:'bold'}} align="right" >Order#</TableCell>
            <TableCell style={{fontWeight:'bold'}} align="right">Store</TableCell>
            <TableCell style={{fontWeight:'bold'}}  align="right">Date</TableCell>
            <TableCell style={{fontWeight:'bold'}}  align="right">Rider</TableCell>
            <TableCell style={{fontWeight:'bold'}}  align="right">Status</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const postDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(row.postTime);
            const postTime = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(row.postTime);
            return(
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             <TableCell align='right'> <button onClick={toggle}  className='odbutton' value={row.orderNumber}>{row.orderNumber}</button></TableCell>
              <TableCell align="right">{row.store}</TableCell>
              <TableCell align="right">{postDate},{postTime}</TableCell>
              <TableCell align="right">{row.ridername}</TableCell>
              <TableCell align="right">{row.status}</TableCell>
            
            </TableRow>

          )}
          )}
        </TableBody>
      </Table>
    </TableContainer>
     
        </div>
         </div>

            
        </div>

    </div>

    <Modal isOpen={modal} toggle={toggle} size='lg'>
      <ModalHeader>Order #{selectedOrder}</ModalHeader>

      <div className='modalContainer'>
      {loading &&
      <>
      <div className='orderDetails'>
            <h5>Order details</h5>
            <p>Status: {status1}</p>
            <p>Store: {store1}</p>
            <p>Created At: {createdAt}</p>
            <p>Customer: {customer}</p>
            <p>Phone: {phone}</p>
            <p>Location:{location}</p>
            <p>Distance:{distance}</p>
            <p>Rider:{rider1}</p>

          </div>
          <div className='orderDetails'>
            <h5>Order items</h5>
            {orderItems.length == 0 &&
              <p>No order items available</p>
            }
            {orderItems.map((val)=>{
              return(
                <div className='orderItem'>
                <p>Item: {val.item}</p>
                <p>QTY: {val.quantity}</p>
                <p>Value: Kes.{val.amount}</p>

                </div>
              )
            })}
          </div>

      </>
      }
      {!loading &&
      <p>Fetching data...</p>
      }
         

      </div>
    </Modal>
    </div>
  )
}
