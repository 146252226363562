import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import './track.css'
import L from 'leaflet';

// Importing marker icons
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';

// Setting up the default icon
const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowSize: [41, 41]  // size of the shadow
});

// This will set the default icon to the one you configured above
L.Marker.prototype.options.icon = DefaultIcon;

export default function Track() {
    const [locations, setLocations] = useState([]);
    const [search, setSearch] = useState('')

  useEffect(() => {
    // Fetch all locations from the backend
    axios.get('https://adminapi.albatroxlogistics.com/api/getRiderlocation')
      .then(response => {
        setLocations(response.data); // Set the array of locations
      })
      .catch(error => {
        console.error('Error fetching location data:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch all locations from the backend
    axios.get(`https://adminapi.albatroxlogistics.com/api/getRiderlocationsearch?search=${search}`)
      .then(response => {
        setLocations(response.data); // Set the array of locations
      })
      .catch(error => {
        console.error('Error fetching location data:', error);
      });
  }, [search]);
  return (

    <div className='homeWrapper'>
    <Header />
    <div className='homeContainer'>
        <div className="sidebar">
            <Sidebar />
        </div>
        <div className="maincontent">
          
    <div  className='storesWrapper'>

    <h4 className='storesTitle'>Riders live location</h4>
    <div className="filterByperiod2">
            <FloatingLabel onChange={(e)=>{setSearch(e.target.value)}} controlId="floatingInputGrid" label="Search rider" className="formInput"  >
              <Form.Control  className='inputSearch'  type="text" placeholder="Search rider" />            
            </FloatingLabel>

            </div>
    <hr />
    <MapContainer 
      center={[-1.286389, 36.817223]} 
      zoom={12} 
      style={{ height: '600px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {locations.map((location, index) => (
        <Marker key={index} position={[location.latitude, location.longitude]}>
          <Popup>
            Latitude: {location.latitude}, Longitude: {location.longitude},Rider: {location.ridername}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  </div>
            
        </div>

    </div>
    </div>


  )
}
