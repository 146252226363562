import {React,useState,useEffect }from 'react'
import { Button,Modal,ModalHeader,Table } from 'reactstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import './returned.css'

export default function Returned() {

    const [riders,setRiders] = useState([]);
    const [items, setItems] = useState([])
    const[start, setStart] = useState('')
    const[end, setEnd] = useState('')
    const [rider, setRider] = useState('')
    const [stores, setStores] = useState([])
    const [store,setStore] = useState('');
    const [modal, setModal] = useState(false)
    const [orderNumber, setOrdernumber] = useState('')
    const [reason, setReason] = useState('');
    const [selected,setSelected] = useState('')


    const toggle=(e)=>{
        const id = e.target.value;
        setSelected(id)
        Axios.get(`https://adminapi.albatroxlogistics.com/api/getThisitem?id=${id}`).then((res)=>{
            setOrdernumber(res.data.orderNumber)
            setReason(res.data.returnReason)
            setModal(!modal)    
        })
      
    }

    const close=()=>{
        setModal(!modal)
    }

    const handleDateChange = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setStart(millisecondsSinceEpoch);
    
      };
      const handleDateChange2 = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setEnd(millisecondsSinceEpoch);
      };

    useEffect(()=>{
       Axios.get('https://adminapi.albatroxlogistics.com/api/returnedItems2').then((res)=>{
        setItems(res.data)
       })
       Axios.get('https://adminapi.albatroxlogistics.com/api/getStores').then((res1)=>{
        setStores(res1.data)
    })
    Axios.get('https://adminapi.albatroxlogistics.com/api/getAllriders').then((res2)=>{
        setRiders(res2.data)
    })


    },[])




  return (
    <div className='homeWrapper'>
    <Header />
    <div className='homeContainer'>
        <div className="sidebar">
            <Sidebar />
        </div>
        <div className="maincontent">
        <div className='storesWrapper'>
    <ToastContainer />
    <h4 className='storesTitle'>Returned items</h4>
    <div className="filterTabs">
            <div className="filterBytime1">
            
            <FloatingLabel className='formInput'  controlId="floatingInputGrid" label='Start perid' onChange={handleDateChange}>
                <Form.Control  className='inputSearch'  type="datetime-local" placeholder='Start period' />
                </FloatingLabel>
                <FloatingLabel className='formInput'  controlId="floatingInputGrid" label='End perid' onChange={handleDateChange2}>
                        <Form.Control  className='inputSearch'  type="datetime-local" placeholder='End period' />
                </FloatingLabel>

            </div>
            <div className="filterBystore1">
            <FloatingLabel controlId="floatingInputGrid" label="Select Store" className="formInput"  >
            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setStore(e.target.value)}}  >
                    <option selected disabled>Select store</option>
                    <option value="All">All</option>
                    {stores.map((val)=>{
                        return(
                            <option value={val.storename}>{val.storename}</option>
                        )
                    })}                  
                </Form.Select>
                        
            </FloatingLabel>

            </div>
            <div className="filterBystore1">
            <FloatingLabel controlId="floatingInputGrid" label="Select rider" className="formInput" >
            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setRider(e.target.value)}}  >
                <option selected disabled>Select rider</option>    
                <option value="All">All</option>  
                {riders.map((val)=>{
                    return(
                        <option value={val.ridername}>{val.ridername}</option>
                    )
                })}                
            </Form.Select>
                    
            </FloatingLabel>

            </div>
          
           
         </div>
    <hr />
  
    <div className="storesTable">
    <Table id="table-to-xls" className="table">
    <thead>
      <tr>
        <th className='tHeading'>#</th>
        <th className='tHeading'>Date</th>
        <th className='tHeading'>Order Number</th>
        <th className='tHeading'>Item</th>
        <th className='tHeading'>Store</th>
        <th className='tHeading'>Rider</th>
        
       

      </tr>
    </thead>
    <tbody>
    {items.map((val, index) => {
        const postDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(val.postTime);
      const dat = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.postTime)
      if(start=='' && end =='' && store =='' && rider == '' ){
      
      return (
         
        <tr key={index} className='roow'>
         <td>{index+1}</td> 
         <td>{postDate},{dat}</td> 
         <td ><button className='checkItem' onClick={toggle} value={val.id}>{val.orderNumber}</button></td>
         <td >{val.item}</td>
          <td>{val.store}</td>       
          <td>{val.ridername}</td> 
                       
        </tr>
        
      );
      }
      if(start=='' && end =='' && store ==val.store  ){
      if(rider == ''){
        return (
         
         <tr key={index} className='roow'>
          <td>{index+1}</td> 
          <td>{postDate},{dat}</td> 
          <td ><button className='checkItem' onClick={toggle} value={val.id}>{val.orderNumber}</button></td>
          <td >{val.item}</td>
           <td>{val.store}</td>       
           <td>{val.ridername}</td> 
                        
         </tr>
         
       );

      }
      if(rider == val.ridername){
        return (
         
         <tr key={index} className='roow'>
          <td>{index+1}</td> 
          <td>{postDate},{dat}</td> 
          <td ><button className='checkItem' onClick={toggle} value={val.id}>{val.orderNumber}</button></td>
          <td >{val.item}</td>
           <td>{val.store}</td>       
           <td>{val.ridername}</td> 
                        
         </tr>
         
       );

      }
      
      }
      ///
      if(start=='' && end =='' && rider ==val.ridername  ){
      if(store == ''){
        return (
         
         <tr key={index} className='roow'>
          <td>{index+1}</td> 
          <td>{postDate},{dat}</td> 
          <td ><button className='checkItem' onClick={toggle} value={val.id}>{val.orderNumber}</button></td>
          <td >{val.item}</td>
           <td>{val.store}</td>       
           <td>{val.ridername}</td> 
                        
         </tr>
         
       );

      }
      if(store == val.store){
        return (
         
         <tr key={index} className='roow'>
          <td>{index+1}</td> 
          <td>{postDate},{dat}</td> 
          <td ><button className='checkItem' onClick={toggle} value={val.id}>{val.orderNumber}</button></td>
          <td >{val.item}</td>
           <td>{val.store}</td>       
           <td>{val.ridername}</td> 
                        
         </tr>
         
       );

      }
      
      }


      ///

      if(val.postTime > start && val.postTime < end){
        if(store == '' && rider == ''){
        return (
         
         <tr key={index} className='roow'>
          <td>{index+1}</td> 
          <td>{postDate},{dat}</td> 
          <td ><button className='checkItem' onClick={toggle} value={val.id}>{val.orderNumber}</button></td>
          <td >{val.item}</td>
           <td>{val.store}</td>       
           <td>{val.ridername}</td> 
                        
         </tr>
         
       );
        }
        if(store == val.store  && rider == ''){
            return (
         
         <tr key={index} className='roow'>
          <td>{index+1}</td> 
          <td>{postDate},{dat}</td> 
          <td ><button className='checkItem' onClick={toggle} value={val.id}>{val.orderNumber}</button></td>
          <td >{val.item}</td>
           <td>{val.store}</td>       
           <td>{val.ridername}</td> 
                        
         </tr>
         
       );    
        }

        if(store == val.store  && rider == val.ridername){
            return (
         
         <tr key={index} className='roow'>
          <td>{index+1}</td> 
          <td>{postDate},{dat}</td> 
          <td ><button className='checkItem' onClick={toggle} value={val.id}>{val.orderNumber}</button></td>
          <td >{val.item}</td>
           <td>{val.store}</td>       
           <td>{val.ridername}</td> 
                        
         </tr>
         
       );    
        }
        if(store == '' && rider == val.ridername){
            return (
         
         <tr key={index} className='roow'>
          <td>{index+1}</td> 
          <td>{postDate},{dat}</td> 
          <td ><button className='checkItem' onClick={toggle} value={val.id}>{val.orderNumber}</button></td>
          <td >{val.item}</td>
           <td>{val.store}</td>       
           <td>{val.ridername}</td> 
                        
         </tr>
         
       );     
        }

      }
    })}
    
    </tbody>
  </Table>
    </div>

</div>
            
        </div>

    </div>

    <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>
            Order item: {selected}
        </ModalHeader>
        <div className='modalContainer'>
            <div className='returnDetails'>
            <p>Order number: {orderNumber}</p>
            <p>Reason for return: {reason}</p>
            <p style={{cursor:'pointer'}} onClick={close}>Close</p>
            </div>

            
        </div>
       
    </Modal>
    </div>
  )
}
