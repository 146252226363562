import {React,useState,useEffect }from 'react'
import { Button,Modal,ModalHeader,Table } from 'reactstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';

export default function Riders() {
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false)
    const [ridername,setRidername] = useState('');
    const [rideremail, setRideremail] = useState('');
    const [riderphone, setRiderphone] = useState('');
    const [password, setPassword] = useState('')
    const [transportMode, setTransportmode] = useState('')
    const [riders,setRiders] = useState([]);
    const [selectedRider, setSelectedrider] = useState('')
    const [stores, setStores] = useState([])
    const [store,setStore] = useState('');
    const [confirmPassword, setConfirmpassword] = useState('')


    const toggle = ()=>{
        setModal(!modal)
    }
    const toggle2 = (e)=>{
        const id = e.target.value;
        setSelectedrider(id)
        setModal2(!modal2)

        Axios.get(`https://adminapi.albatroxlogistics.com/api/getThisrider?id=${id}`).then((res)=>{
            setRidername(res.data.ridername)
            setRideremail(res.data.rideremail);
            setRiderphone(res.data.riderphone);
            setPassword(res.data.password);

        })
    }
    
    const toggle3 =()=>{
        setModal3(!modal3)
    }

    useEffect(()=>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAllriders').then((res)=>{
            setRiders(res.data)
        })
        Axios.get('https://adminapi.albatroxlogistics.com/api/getStores').then((res1)=>{
            setStores(res1.data)
        })
    },[])

    const submitUser = () =>{
        if(ridername !== '' && rideremail !== '' && riderphone !== '' && password !== '' && transportMode !=='' && store !=='' && confirmPassword){
            if(password == confirmPassword){
            const randomPart = Math.random().toString(16).substring(2, 8).toUpperCase();
            const color =  `#${randomPart}`;
            Axios.post('https://adminapi.albatroxlogistics.com/api/postRider',{
                ridername,rideremail,riderphone,password,transportMode,store,color
            }).then((res)=>{
                if(res.data.exist){
                    toast.error('rider email already exists!')
                }
                else if(res.data.success){
                    Axios.get('https://adminapi.albatroxlogistics.com/api/getAllriders').then((res)=>{
                        setRiders(res.data)
                    })
                    setModal(!modal);
                    toast.success('Rider has been added')
                }

            }).catch((err)=>{
                toast.error('Network connection error!')
            })
        }
        else{
            toast.error('Passwords do no match')
        }
        }
        else{
            toast.error('All fields are requred!')
        }
    }

    const editUser = ()=>{
        Axios.put('https://adminapi.albatroxlogistics.com/api/updateRider',{
            ridername,rideremail,riderphone,password,selectedRider,transportMode
        }).then((res)=>{
            if(res.data.success){
                Axios.get('https://adminapi.albatroxlogistics.com/api/getAllriders').then((res)=>{
                    setRiders(res.data)
                })
                toast.success('Changes have been saved!');
                setModal2(false);

            }
            
            else{
                toast.error('Something went wrong. Please contact support!')
            }
        }).catch((err)=>{
            toast.error('Network connection error!')
        })
    }

    const deleteUser = () =>{
        Axios.delete(`https://adminapi.albatroxlogistics.com/api/deleteRider?id=${selectedRider}`).then((res)=>{
            if(res.data.success){
                Axios.get('https://adminapi.albatroxlogistics.com/api/getAllriders').then((res)=>{
                    setRiders(res.data)
                })

                setModal2(false)
                setModal3(false)
                toast.success('Rider has been deleted')
            }
            else{
                toast.error('Something went wrong! Please contact support')
            }
            
        }).catch((err)=>{
            toast.error('Network connection error')
        })
    }
  return (

    <div className='homeWrapper'>
    <Header />
    <div className='homeContainer'>
        <div className="sidebar">
            <Sidebar />
        </div>
        <div className="maincontent">
        <div className='storesWrapper'>
    <ToastContainer />
    <h4 className='storesTitle'>Riders</h4>
    <hr />
    <div className="storesIntro">
        <Button onClick={toggle} color='success'>Add New rider</Button>
    </div>
    <div className="storesTable">
    <Table id="table-to-xls" className="table">
    <thead>
      <tr>
        <th className='tHeading'>#</th>
        <th className='tHeading'>Name</th>
        
        <th className='tHeading'>Email</th>
        <th className='tHeading'>Phone</th>
        <th className='tHeading'>Total Orders</th>
       

      </tr>
    </thead>
    <tbody>
      {riders.map((val, index) => {
      
        return (
           
          <tr key={index} className='roow'>
           <td>{index+1}</td> 
           <td ><button onClick={toggle2} className='orderBtn' value={val.id}>{val.ridername}</button></td>
            <td>{val.rideremail}</td>       
            <td>{val.riderphone}</td> 
            <td>{val.TotalOrders}</td>               
          </tr>
          
        );
      })}
    </tbody>
  </Table>
    </div>

    <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>New Rider</ModalHeader>
        <div className="modalWrapper">
            <p style={{color:'red'}}>*Fill the details below. All fields are mandatory</p>
            <FloatingLabel className='formInput' onChange={(e)=>{setRidername(e.target.value)}} controlId="floatingInputGrid" label="Rider name">
                <Form.Control  className='inputSearch'  type="text" placeholder="e.g John Doe" />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setRideremail(e.target.value)}} controlId="floatingInputGrid" label="Rider email">
                <Form.Control  className='inputSearch'  type="text" placeholder="e.g johndoe@example.com" />
            </FloatingLabel>
            <FloatingLabel className='formInput'  controlId="floatingInputGrid" label="Mode of transport">
            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setTransportmode(e.target.value)}}  >
                    <option selected disabled>Select transport mode</option>
                    <option value="Track">Track</option>
                    <option value="Track">Motorcycle</option>
                    <option value="Track">Bicycle</option>

                        
                </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingInputGrid" label="Select rider" className="selectMeter" >
                <Form.Select className="selectMeter"  aria-label="Floating label select example" onChange={(e)=>{setStore(e.target.value)}} >
                    <option selected disabled>Assign store</option>
                    {stores.map((val)=>{
                        return(
                            <option value={val.storename}>{val.storename}</option>
                        )
                    })}                            
                </Form.Select>
                    
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setRiderphone(e.target.value)}} controlId="floatingInputGrid" label="Rider phone">
                <Form.Control  className='inputSearch'  type="text" placeholder="e.g 0712345678" />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setPassword(e.target.value)}} controlId="floatingInputGrid" label="Rider password">
                <Form.Control  className='inputSearch'  type="password" placeholder="password" />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setConfirmpassword(e.target.value)}} controlId="floatingInputGrid" label='Confirm password'>
                <Form.Control  className='inputSearch'  type="password" placeholder='Confirm password' />
            </FloatingLabel>
            <Button onClick={submitUser} color='info' className='modBtn'>Add rider</Button>
        </div>

    </Modal>

    <Modal isOpen={modal2} toggle={toggle2}>
        <ModalHeader>Edit rider</ModalHeader>
        <div className="modalWrapper">
            <p>Edit user</p>
            <FloatingLabel className='formInput' onChange={(e)=>{setRidername(e.target.value)}} controlId="floatingInputGrid" label={ridername}>
                <Form.Control  className='inputSearch'  type="text" placeholder={ridername} />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setRideremail(e.target.value)}} controlId="floatingInputGrid" label={rideremail}>
                <Form.Control  className='inputSearch'  type="text" placeholder={rideremail} />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setRiderphone(e.target.value)}} controlId="floatingInputGrid" label={riderphone}>
                <Form.Control  className='inputSearch'  type="text" placeholder={riderphone} />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setPassword(e.target.value)}} controlId="floatingInputGrid" label={password}>
                <Form.Control  className='inputSearch'  type="password" placeholder={password} />
            </FloatingLabel>
            
            <div className="modButtons">
            <Button onClick={editUser} color='warning' className='modBtn'>Save changes</Button>
            <Button onClick={toggle3} color='danger' className='modBtn'>Remove rider</Button>
            </div>
           
        </div>

    </Modal>

    <Modal isOpen={modal3} toggle={toggle3}>
        <ModalHeader>Remove rider</ModalHeader>
        <div className="modalWrapper">
            <p>Are you sure you want to remove this rider?</p>
            <div className="modButtons">
                <Button onClick={deleteUser} color='success' className='modBtn'>YES</Button>
                <Button color='danger' className='modBtn' onClick={toggle3}>NO</Button>
            </div>
        </div>

    </Modal>



</div>
            
        </div>

    </div>
    </div>
   
  )
}
