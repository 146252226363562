import {React, useState, useEffect} from 'react'
import Axios from 'axios'
import './orders.css'
import { Table,Modal,ModalHeader, Button } from 'reactstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Sidebar from '../sidebar/Sidebar';
import Header from '../header/Header';


export default function Orders() {
    const [currentDate, setCurrentDate] = useState(getDate());
    const [pendingOrders, setPendingorders] = useState([]);
    const [dispatchOrders, setDispatchorders] = useState([]);
    const [transitOrders, setTransitorders] = useState([]);
    const [deliveredOrders, setDeliveredorders] = useState([]);
    const [returnedOrders, setReturnedorders] = useState([]);
    const [cancelledOrders, setCancelledorders] = useState([]);
    const [allOrders, setAllorders] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false)
    const [selectedOrder,setSelectedorder] = useState('');
    const [orderNumber, setOrdernumber] = useState('');
    const [store, setStore] = useState('');
    const [orderValue, setOrdervalue] = useState('');
    const [customerName, setCustomername] = useState('');
    const [customerPhone, setCustomerphone] = useState("");
    const [location, setLocation] = useState("")
    const [landmark, setLandmark] = useState("")
    const [instructions, setInstructions] = useState('')
    const [status, setStatus] = useState('');
    const [postTime, setPosttime] = useState('');
    const [assignedTime,setAssignedtime] = useState('');
    const [dispatchTime, setDispatchtime] = useState('');
    const [arrivalTime, setArrivaltime] = useState('');
    const [deliveryTime, setDeliverytime] = useState('')
    const [cancelTime, setCanceltime] = useState("");
    const [returnTime, setReturntime] = useState('');
    const [rider, setRider] = useState("");
    const [distance, setDistance] = useState('');
    const [riders, setRiders] = useState([]);
    const [selectedRider, setSelectedrider] = useState('')
    const [date, setDate] = useState('')
    const [transportMode, setTransportmode] = useState('')
    const [token, setToken] = useState('')
    const [response1,setResponse1] = useState('')



    const toggle =(e)=>{
        const id = e.target.value;
        setSelectedorder(id);
        Axios.get(`https://adminapi.albatroxlogistics.com/api/getThisorder?id=${id}`).then((res)=>{
            setOrdernumber(res.data.orderNumber)
            setOrdervalue(res.data.orderValue)
            setStore(res.data.store)
            setCustomername(res.data.customerName)
            setCustomerphone(res.data.customerPhone)
            setLocation(res.data.location);
            setLandmark(res.data.landmark);
            setStatus(res.data.status)
            setPosttime(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(res.data.postTime))
            setAssignedtime(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(res.data.assignedTime))
            setDispatchtime(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(res.data.dispatchedTime))
            setArrivaltime(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(res.data.arrivalTime))
            setDeliverytime(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(res.data.deliveryTime))
            setCanceltime(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(res.data.cancelTime))
            setReturntime(new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(res.data.returnTime));
            setRider(res.data.ridername);
            setDistance(res.data.distance)
            setInstructions(res.data.instructions)
        })
        setModal(!modal)
    }

    const toggle2 = () =>{
        setModal2(!modal2)
    }

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${month}/${date}/${year}`;
      }
      let result = Date.parse(currentDate);

      const getStats = () =>{
        Axios.get(`https://adminapi.albatroxlogistics.com/api/getPendingorders?today=${result}`).then((res)=>{
            setPendingorders(res.data)
        })

        Axios.get(`https://adminapi.albatroxlogistics.com/api/getDispatchorders?today=${result}`).then((res)=>{
            setDispatchorders(res.data)
        })
        Axios.get(`https://adminapi.albatroxlogistics.com/api/getTransitorders?today=${result}`).then((res)=>{
            setTransitorders(res.data)
        })
        Axios.get(`https://adminapi.albatroxlogistics.com/api/getTodayDeliveredorders?today=${result}`).then((res)=>{
            setDeliveredorders(res.data)
        })
        Axios.get(`https://adminapi.albatroxlogistics.com/api/getTodayCancelledorders?today=${result}`).then((res)=>{
            setCancelledorders(res.data)
        })
        Axios.get(`https://adminapi.albatroxlogistics.com/api/getTodayReturnedorders?today=${result}`).then((res)=>{
            setReturnedorders(res.data)
        })

        Axios.get(`https://adminapi.albatroxlogistics.com/api/getTodayAllorders?today=${result}`).then((res)=>{
            setAllorders(res.data)
        })

      }
      
    useEffect(()=>{
       getStats();
    },[result])

    const getOrder = (e) =>{
        const id = e.target.value;
        console.log(`ID IS ${id}`)
    }

    useEffect(()=>{

        Axios.get(`https://adminapi.albatroxlogistics.com/api/getAllriders2?store=${store}&transportMode=${transportMode}`).then((res)=>{
            setRiders(res.data)
        })
    
    },[transportMode])


    const postData = async () => {
        ///Get access token

        const username = 'BImtlrgdjkYTDG1DYXbzyGaUJeLqLnU0';
        const password = 'zVLIFskMr8ma7yRo';
        const credentials = btoa(`${username}:${password}`);
    
        const bodyData = {
          name: 'John Doe',
          email: 'john.doe@example.com'
        };
    
        try {
          const result = await fetch('https://calprsp.collectionafrica.co.ke/api/v1/authenticate?grant_type=client_credentials', {
            method: 'POST',
            headers: {
              'Authorization': `Basic ${credentials}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyData)
          });
    
          const data = await result.json();
          setResponse1(data);
    
          if (data.access_token) {
            setToken(data.access_token); 
            const tk = data.access_token
                // Fetch phone numbers from the backend
      const phoneResponse = await fetch(`https://adminapi.albatroxlogistics.com/api/getPhonenumbers?rider=${selectedRider}`); // Adjust URL
      const phoneNumbers = await phoneResponse.json();

      for (let number of phoneNumbers) {
        const bodyData = {
          sender_id: 'YUNSHU',
          phone_number: number.riderphone, // Assuming phone_number field
          message: `You have a new order assignment. Order number #${orderNumber} at ${store}.`
        };

        // Send SMS for each phone number
        const result = await fetch('https://calprsp.collectionafrica.co.ke/api/v1/sms/single', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${tk}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        });

        const data = await result.json();
        toast.success(`SMS sent to ${number.riderphone}`);
      }
            
           
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

    const assignOrder = () =>{
        if(selectedRider !== ''){
            Axios.put('https://adminapi.albatroxlogistics.com/api/assignRider',{
                selectedOrder,selectedRider
            }).then((res)=>{
                if(res.data.success){
                    toast.success(`Order #${orderNumber} has been assigned`);
                    Axios.get(`https://adminapi.albatroxlogistics.com/api/getTodayAllorders?today=${result}`).then((res)=>{
                        setAllorders(res.data)
                    })
                    getStats()
                    postData()
                    setModal(!modal)
    
    
                }
                else{
                    toast.error('Something went wrong. Please contact support')
                }
            }).catch((err)=>{
                toast.error('Network connection error')
            })

        }
        else{
            toast.error('Please select a rider!')
        }
        
    }

    const dispatchOrder = () =>{
      
            Axios.put('https://adminapi.albatroxlogistics.com/api/dispatchOrder',{
                selectedOrder
            }).then((res)=>{
                if(res.data.success){
                    toast.success(`Order #${orderNumber} has been dispatched`);
                    Axios.get(`https://adminapi.albatroxlogistics.com/api/getTodayAllorders?today=${result}`).then((res)=>{
                        setAllorders(res.data)
                    })
                    getStats()
                    setModal(!modal)
    
    
                }
                else{
                    toast.error('Something went wrong. Please contact support')
                }
            }).catch((err)=>{
                toast.error('Network connection error')
            })


        
    }

    const cancelOrder = ()=>{
        Axios.put('https://adminapi.albatroxlogistics.com/api/cancelOrder',{
            selectedOrder
        }).then((res)=>{
            if(res.data.success){
                setModal(false)
                setModal2(false);
                getStats();
                toast.success(`Order ${orderNumber} has been cancelled`)
            }
            else{
                toast.error('Something went wrong. Please contact support')
            }
        }).catch((err)=>{
            setModal2(false);
            toast.error('Network connection error')
        })
    }


    const handleDateChange = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setDate(millisecondsSinceEpoch);
    
      };
    
    useEffect(()=>{
        if(date){
            Axios.get(`https://adminapi.albatroxlogistics.com/api/getSpecificdayorders?date=${date}`).then((res)=>{
                setAllorders(res.data)
                
            })
    
            Axios.get(`https://adminapi.albatroxlogistics.com/api/getPendingorders?today=${date}`).then((res)=>{
                setPendingorders(res.data)
            })
    
            Axios.get(`https://adminapi.albatroxlogistics.com/api/getDispatchorders?today=${date}`).then((res)=>{
                setDispatchorders(res.data)
            })
            Axios.get(`https://adminapi.albatroxlogistics.com/api/getTransitorders?today=${date}`).then((res)=>{
                setTransitorders(res.data)
            })
            Axios.get(`https://adminapi.albatroxlogistics.com/api/getTodayDeliveredorders?today=${date}`).then((res)=>{
                setDeliveredorders(res.data)
            })
            Axios.get(`https://adminapi.albatroxlogistics.com/api/getTodayCancelledorders?today=${date}`).then((res)=>{
                setCancelledorders(res.data)
            })
            Axios.get(`https://adminapi.albatroxlogistics.com/api/getTodayReturnedorders?today=${date}`).then((res)=>{
                setReturnedorders(res.data)
            })
    

        }
      

    },[date])

  return (

    <div className='homeWrapper'>
    <Header />
<div className='homeContainer'>
    <div className="sidebar">
        <Sidebar />
    </div>
    <div className="maincontent">
    <div className='ordersWrapper'>
        <ToastContainer />
        <div className="ordersIntro">
            <div className="introTab">
             <h4>Today Orders: {allOrders.length}</h4>
             <FloatingLabel className='formInput2'  controlId="floatingInputGrid" label='Change date' onChange={handleDateChange}>
                <Form.Control  className='inputSearch'  type="date" placeholder='Change date' />
            </FloatingLabel>
            </div>          
            <div className="orderTabs">
            <p className='pending'>Unassigned orders: {pendingOrders.length}</p>
            <p className='dispatch'>Orders in dispatch: {dispatchOrders.length}</p>
            <p className='transit'>Orders in transit: {transitOrders.length}</p>
            <p className='delivered'>Delivered orders: {deliveredOrders.length}</p>
            <p className='returned'>Returned orders: {returnedOrders.length}</p>
            <p className='cancelled'>Cancelled orders: {cancelledOrders.length}</p>
            
            </div>

        </div>
        <hr />
        <div className="ordersTable">
        <Table id="table-to-xls" className="table">
        <thead>
          <tr>
            <th className='tHeading'>Order Number</th>
            <th className='tHeading'>Time</th>
            
            <th className='tHeading'>Store</th>
            <th className='tHeading'>Customer name</th>
            <th className='tHeading'>rider</th>
            <th className='tHeading'>Status</th>
          </tr>
        </thead>
        {allOrders.length !== 0 &&
        <tbody>
        {allOrders.map((val, index) => {
          const postDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(val.postTime);
          const postTime = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.postTime);

          return (
             
            <tr key={index} className='roow'>
             
             <td className={val.status} ><button onClick={toggle} className='orderBtn' value={val.id}>{val.orderNumber}</button></td>
              <td className={val.status}>{postTime}</td>       
              <td className={val.status}>{val.store}</td>
              <td className={val.status}>{val.customerName}</td>
              <td className={val.status}>{val.ridername}</td>
              <td className={val.status}>{val.status}</td>
             
            </tr>
            
          );
        })}
      </tbody>
        }
        {allOrders.length == 0 && 

            <p>No data to display</p>
        }
        
      </Table>
        </div>

        <Modal isOpen={modal} toggle={toggle} size='xl'>
        <ModalHeader>#{orderNumber} Order details - {status}</ModalHeader>
        <div className='orderModal'>
            <div className="mods">
            <div className="mod">
            <p><strong style={{color:'#3970c3'}}>Store:</strong> {store}</p>
            <p><strong style={{color:'#3970c3'}}>Order Value:</strong> {orderValue}</p>
            <p><strong style={{color:'#3970c3'}}>Customer name:</strong> {customerName}</p>
            <p><strong style={{color:'#3970c3'}}>Customer contact:</strong> {customerPhone}</p>
            <p><strong style={{color:'#3970c3'}}>Location:</strong> {location}</p>

            </div>
            <div className="mod">
            <p><strong style={{color:'#3970c3'}}>Landmark:</strong> {landmark}</p>
            <p><strong style={{color:'#3970c3'}}>Time Posted:</strong> {postTime}</p>
            <p><strong style={{color:'#3970c3'}}>Time Assigned:</strong> {assignedTime}</p>
            <p><strong style={{color:'#3970c3'}}>Time Dispatched:</strong> {dispatchTime}</p>
            </div>
            <div className="mod">
            <p><strong style={{color:'#3970c3'}}>Arrival Time:</strong> {arrivalTime}</p>
            <p><strong style={{color:'#3970c3'}}>Delivery time:</strong> {deliveryTime}</p>
            <p><strong style={{color:'#3970c3'}}>Assigned Rider:</strong> {rider}</p>
            <p><strong style={{color:'#3970c3'}}>Distance:</strong> {distance}km</p>

            </div>
            
               
            
            </div>
            {status == 'Pending' &&
                    <div className="modActions">
                        <h6>Assign order to rider</h6>
                        <FloatingLabel className='formInput'  controlId="floatingInputGrid" label="Mode of transport">
                            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setTransportmode(e.target.value)}}  >
                                    <option selected disabled>Select transport mode</option>
                                    <option value="Track">Track</option>
                                    <option value="Track">Motorcycle</option>
                                    <option value="Track">Bicycle</option>

                                        
                                </Form.Select>
                            </FloatingLabel>
                        <FloatingLabel controlId="floatingInputGrid" label="Select rider" className="selectMeter" >
                            <Form.Select className="selectMeter"  aria-label="Floating label select example" onChange={(e)=>{setSelectedrider(e.target.value)}} >
                                <option selected disabled>Select rider</option>
                                {riders.map((val)=>{
                                return(
                                    <option value={val.rideremail}>{val.ridername}</option>
                                )
                                })}                            
                            </Form.Select>
                    
                            </FloatingLabel>
                        <Button onClick={assignOrder} color='info' className='modBtn'>Assign</Button>

                    </div>

                }
                {status == 'Dispatch' &&
                    <div className="modActions">
                    <Button className='btnDispatch' onClick={dispatchOrder} color='success'>Dispatch order</Button>
                        <h6>Reassign order to rider</h6>
                        
                        <FloatingLabel controlId="floatingInputGrid" label="Select rider" className="selectMeter" >
                            <Form.Select className="selectMeter"  aria-label="Floating label select example" onChange={(e)=>{setSelectedrider(e.target.value)}} >
                                <option selected disabled>Select rider</option>
                                {riders.map((val)=>{
                                return(
                                    <option value={val.rideremail}>{val.ridername}</option>
                                )
                                })}                            
                            </Form.Select>
                    
                            </FloatingLabel>
                            <div className="modButtons">
                                <Button onClick={assignOrder} color='info' className='modBtn'>Reassign</Button>
                                <Button onClick={toggle2} color='danger' className='modBtn'>Cancel Order</Button>
                            </div>
                        

                    </div>

                }
                {status == 'Transit' &&
                    <div className="modActions">
                        <h6>Reassign order to rider</h6>
                        <FloatingLabel controlId="floatingInputGrid" label="Select rider" className="selectMeter" >
                            <Form.Select className="selectMeter"  aria-label="Floating label select example" onChange={(e)=>{setSelectedrider(e.target.value)}} >
                                <option selected disabled>Select rider</option>
                                {riders.map((val)=>{
                                return(
                                    <option value={val.rideremail}>{val.ridername}</option>
                                )
                                })}                            
                            </Form.Select>
                    
                            </FloatingLabel>
                            <div className="modButtons">
                                <Button onClick={assignOrder} color='info' className='modBtn'>Reassign</Button>
                                <Button onClick={toggle2} color='danger' className='modBtn'>Cancel Order</Button>
                            </div>
                        

                    </div>

                }
          
        </div>


        </Modal>

        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader>Cancellation confirmation</ModalHeader>
            <div className="orderModal">
                <p>*Are your sure you want to cancel order #{orderNumber}</p>
                <div className="modButtons">
                    <Button onClick={cancelOrder} className='modBtn' color='success'>YES</Button>
                    <Button onClick={toggle2} className='modBtn' color='danger'>NO</Button>
                </div>
            </div>
            

        </Modal>

    </div>
        
    </div>

</div>
</div>
  
  )
}
