import {React, useState,useEffect} from 'react'
import './profile.css'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'
import { Button } from 'reactstrap';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

export default function Profile() {

    const[email, setEmail] = useState('');
    const[username, setUsername] = useState('');
    const[userphone, setUserphone] = useState('');
    const[userrole, setUserrole] = useState('')
    const[password, setPassword] = useState('')
    const navigate = useNavigate();
 
  
  
    Axios.defaults.withCredentials = true;
    let mail = ''
    useEffect(() =>{
      Axios.get('https://adminapi.albatroxlogistics.com/api/profile').then((response)=>{
        if(response.data.valid){
            setEmail(response.data.email)
        }
        else{
          //pass
        //   navigate('/')
        }
      
      })
    },[])
  
    useEffect(()=>{
        Axios.get(`https://adminapi.albatroxlogistics.com/api/getUserdetails?email=${email}`).then((res)=>{
            setUsername(res.data.username)
            setUserphone(res.data.userphone)
            setUserrole(res.data.role);
            setPassword(res.data.password);
         
  
        })
  
    },[email])

    const logout=()=>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/logout').then((res)=>{
            if(res.data.logout){
                  navigate('/')
            }
            else{

            }
        })
    }



  return (
    <div className='homeWrapper'>
    <Header />
<div className='homeContainer'>
    <div className="sidebar">
        <Sidebar />
    </div>
    <div className="maincontent">
    <div className='profileWrapper'>
        <div className="profileIntro">
            <h4>My profile</h4>

        </div>
        <hr />
        <div className="profileBody">
            <img src="/assets/account.png" alt="" className='accountIcon' />
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label={username}>
                <Form.Control  className='inputSearch'  type="password" placeholder={username} disabled />
            </FloatingLabel>
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label={email}>
                <Form.Control  className='inputSearch'  type="password" placeholder={email} disabled />
            </FloatingLabel>
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label={userphone}>
                <Form.Control  className='inputSearch'  type="password" placeholder={userphone} disabled />
            </FloatingLabel>
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label={userrole}>
                <Form.Control  className='inputSearch'  type="password" placeholder={userrole} disabled />
            </FloatingLabel>
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label={password}>
                <Form.Control  className='inputSearch'  type="password" placeholder={password} disabled />
            </FloatingLabel>

            <div className="profileButton">
                <Button className='logout' color='danger' onClick={logout}>Logout</Button>
            </div>

        </div>
       
    </div>
        
    </div>

</div>
</div>
   
  )
}
